import {IMAGE_LOADER, ImageLoaderConfig} from '@angular/common'
import {Provider} from '@angular/core'
import {memoize} from '../../utils/memoize'
import {transformBuildigoImageUrl} from './buildigo-image-api'
import {transformDatocmsImageUrl} from './datocms'

const imageApiDomains = [
  /^https:\/\/(files|vault)([.-]\w+)*\.buildigo\.ch(?:.*)?$/, // any domains starting with https://profiles and finishing with buildigo.ch
  /^(?!https?:\/\/|www\.)\S+$/, // relative paths means static files: image api is deployed and available for static images
  /d3asefko8okrsn.cloudfront.net/, // TODO: remove
]
const datocmsImageApiDomain = /datocms-assets.com/

export function imageApiLoader(config: ImageLoaderConfig): string {
  if (imageApiDomains.some(regex => config.src.match(regex))) {
    // buildigo image api
    return transformBuildigoImageUrl(config.src, {width: config.width})
  } else if (config.src.match(datocmsImageApiDomain)) {
    return transformDatocmsImageUrl(config.src, {width: config.width})
  } else {
    return config.src
  }
}

export const provideImageApiLoader: () => Provider[] = () => [
  {
    provide: IMAGE_LOADER,
    useValue: memoize(imageApiLoader), // use memoized function to avoid costly regex evals
  },
]
